import { useEffect, useState } from 'react'
import { fetchVehicles } from '../libs/getVehiclesBYD'

function useFetchAndCacheVehicles(isUpdated: boolean): boolean {
    const [isFetchComplete, setFetchComplete] = useState(false)

    useEffect(() => {
        async function fetchAndCacheVehicles() {
            await fetchVehicles(isUpdated)
            setFetchComplete(true)
        }
        fetchAndCacheVehicles()
    }, [isUpdated])

    return isFetchComplete
}

export default useFetchAndCacheVehicles
