interface Cache {
  set<T>(key: string, data: T): void
  get<T>(key: string): T | undefined
  delete(key: string): void
  clear(): void
  clearKey(key: string): void
}

class MemoryCache implements Cache {
  private cache: { [key: string]: any } = {}

  set<T>(key: string, data: T): void {
    this.cache[key] = data
  }

  get<T>(key: string): T | undefined {
    return this.cache[key] as T | undefined
  }

  delete(key: string): void {
    delete this.cache[key]
  }

  clear(): void {
    this.cache = {}
  }

  clearKey(key: string): void {
    delete this.cache[key]
  }
}

const globalCache = (typeof global !== 'undefined' ? global : window) as any
if (!globalCache.memoryCache) {
  globalCache.memoryCache = new MemoryCache()
}
const memoryCache: Cache = globalCache.memoryCache

export default memoryCache
